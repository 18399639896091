body {
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: $default-font-size;
  &.modal-open {
    padding: 0 !important;
  }
}

.pcoded-header.header-blue {
  background: $blue-color-dark;
  .dropdown .profile-notification .pro-body {
    padding: 0;
    li {
      margin: 0;
      padding: 5px 10px;
      &:hover {
        background: #274775;
        box-shadow: none;
      }
    }
  }
}

.pcoded-navbar.menu-light .pcoded-inner-navbar > li > a.active {
  background: $blue-color-dark;
}

.card {
  .card-block,
  .card-body {
    padding: 50px 30px;
  }
}

@media (max-width: 1199px) {
  .card {
    .card-block,
    .card-body {
      padding: 20px;
    }
  }
}

.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    color: #fff;
    background-color: $primary-color;
  }
}

.img-thumbnail {
  width: 300px;
}

.table {
  thead th {
    font-size: $default-font-size;
  }
  tbody .btn.btn-icon {
    width: 35px;
    height: 35px;
    font-size: $default-font-size;
  }
  th,
  td {
    vertical-align: middle;
  }
}

div.dataTables_wrapper {
  div.dataTables_length select {
    width: 100px;
  }
  .dataTables_paginate .paginate_button.active {
    background: $blue-color-dark;
    border-color: $dark-color;
    &:hover {
      background: #275cab;
      border-color: $dark-color;
    }
  }
}

.bg-header-default {
  background-color: $grey-header;
}

.figure-display > div:not(:last-child) {
  border-right: 1px solid $theme-border;
}

@media (max-width: 767px) {
  .figure-display > div {
    &:nth-child(odd) {
      border-right: 1px solid $theme-border;
    }
    &:nth-child(even) {
      border-right: none;
    }
    &:first-child,
    &:nth-child(2) {
      border-bottom: 1px solid $theme-border;
    }
  }
}

#filtering-section {
  label {
    width: 25%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  #filtering-section {
    label {
      width: 100%;
    }
  }
}

.form-control {
  &:focus {
    background-image: unset;
    border-bottom: 1px solid $gray-400;
  }
  &:disabled,
  &[readonly] {
    background-color: transparent;
    opacity: 1;
    border-bottom-style: dashed;
    color: #b5b5b5 !important;
    pointer-events: none;
  }
  font-size: $default-font-size;
}

form .form-control {
  padding: 0.625rem 0.25rem;
}

.was-validated .form-control,
.was-validated .form-check-input {
  border-color: $gray-400;
  &:valid,
  &.is-valid {
    border-color: $gray-400;
    background-image: none;
    &:focus {
      border-color: $gray-400;
    }
    ~ .form-check-label {
      color: inherit;
    }
  }
  &:invalid,
  &.is-invalid {
    background-image: none;
  }
}
.was-validated {
  .form-control,
  .form-check-input {
    &:invalid,
    &.is-invalid {
      ~ .invalid-feedback {
        display: block;
      }
    }
  }
  select.form-control {
    &:valid,
    &.is-valid,
    &:invalid,
    &.is-invalid {
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
        no-repeat;
      background-size: 12px;
      background-position: calc(100% - 20px) center;
    }
  }
}

.form-control.is-valid {
  border-color: $gray-400;
  background-image: none;
  select {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
  }
  &:focus {
    border-color: $gray-400;
  }
}

.form-control.is-invalid {
  background-image: none;
  select {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}

.custom-datepicker-wrapper {
  input {
    cursor: pointer;
  }
  .datepicker-icon {
    height: 100%;
    width: 10%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background-color: transparent;
    font-size: 1.25rem;
    color: $datepicker-icon;
    &:focus {
      outline: none;
    }
  }
}

.react-datepicker {
  button {
    &:focus {
      outline: none;
    }
  }
}
@media (min-width: 1900px) {
  canvas.signatureCanvas {
    width: 500px;
    height: 250px;
  }
  canvas.signCanvas {
    width: 500px;
    height: 250px;
  }
}
@media (min-width: 1600px) and (max-width: 1899px) {
  canvas.signatureCanvas {
    width: 400px;
    height: 200px;
  }
  canvas.signCanvas {
    width: 400px;
    height: 200px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  canvas.signatureCanvas {
    width: 300px;
    height: 150px;
  }
  canvas.signCanvas {
    width: 300px;
    height: 150px;
  }
}
@media (min-width: 1020px) and (max-width: 1199px) {
  canvas.signatureCanvas {
    width: 200px;
    height: 200px;
  }
  canvas.signCanvas {
    width: 200px;
    height: 200px;
  }
}
.swal2-container {
  z-index: 10000 !important;
}

//Original Event Tag without Colour
.event-tag {
  margin: 0.1rem 0;
  padding: 0.25rem;
  color: white;
  word-break: keep-all;
  border-radius: 0.25rem;
  font-size: 16px; //1rem;
  line-height: 2rem;
  font-weight: 900;
  margin-right: 0.25rem;
}

table.table-striped tbody {
  tr:hover {
    cursor: default;
  }
}

select.form-control {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  -webkit-appearance: none;
  appearance: none;
  &:disabled {
    background-size: 12px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='lightgrey'><polygon points='0,0 100,0 50,50'/></svg>");
  }
  &:focus {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
    border-bottom: 1px solid $gray-400;
  }
  &:not(:disabled):not([readonly]).empty {
    color: #adb5bd;
  }
}

.calendar.card-body {
  min-height: 300px;
}

.btn.default-theme {
  color: #212529;
  background-color: #f0f2f5;
  border-color: #cbd2dd;
  &.btn-sm {
    padding: 0.357143rem 0.642857rem;
    i {
      font-size: 16px;
    }
  }
  &.btn-lg {
    padding: 0.625rem 1.25rem;
    i {
      font-size: 20px;
    }
  }
  &:hover {
    color: #212529;
    background-color: #cbd2dd;
    border-color: #adb8c8;
  }
  &:disabled {
    pointer-events: none;
  }
}

.custom-control {
  z-index: 0;
}

.align-left {
  text-align: left;
}

#CareLog_list_table {
  border-collapse: collapse !important;
  thead th {
    border: 1px solid #ced4da !important;
    border-bottom-width: 0px !important;
  }
  th,
  td {
    border: 1px solid #ced4da !important;
  }
}