// text
.text-warning-dark{
    color: $warning-color-dark;
}
.text-danger-dark{
    color: $danger-color-dark;
}
.text-caution{
    color: $caution-color;
}

.btn-warning-dark{
    color: #fff;
    background-color: $warning-color-dim;
    border-color: $warning-color-dim;
}
.btn-warning-dark:hover{
    color: #fff;
    background-color: $warning-color-dimmer;
    border-color: $warning-color-dimmer;
}

// survey
.survey-xl-label{
    font-size: $survey-section-header-size;
    font-weight: 700;
}
.survey-lg-label, .summary-lg-label{
    font-size: $survey-label-size;
    font-weight: 700;
}
.summary-lg-label.with-underline{
    text-decoration: underline;
}
.summary-lg-desc{
    font-size: large;
    font-weight: 700;
}

.well-being-survey{
    form{
        & > div{
            margin-top: 20px;
        }
        p{
            margin: 0;
        }
        .form-control{
            font-size: $default-font-size;
            padding: 0 10px;
            &:disabled, &.disabled, &[readonly] {
                background-color: rgba(239, 239, 239, 0.3);
                border: 1px solid $theme-border;
                pointer-events: none;
                opacity: 1;
                color: $secondary-color;
            }
        }
        button.datepicker-icon.disabled{
            cursor: default;
        }
        .form-check{
            align-items: baseline;
            input{
                width: 20px;
                height: 20px;
                margin-right: 5px;
                &.form-radio-custom{
                    display: none;
                }
            }
            .form-check-label{
                font-size: $survey-label-size;
                font-weight: 700;
            }
        }
        .reset-radio{
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: 15px;
        }
        input[type="text"]{
            width: 100%;
            height: calc(25vh*0.2);
            border: 1px solid $theme-border;
            padding: 0 10px;
            &:disabled, &.disabled, &[readonly] {
                color: $secondary-color;
                cursor: default;
            }
        }
        textarea{
            width: 100%;
            border: 1px solid $theme-border;
            padding: 0 10px;
            &:disabled, &.disabled, &[readonly] {
                color: $secondary-color;
                cursor: default;
            }
        }
        button.survey-radio-button{
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
            line-height: 1;
            p{
                font-size: $survey-label-size;
                font-weight: 600;
                line-height: 1.2;
            }
            .survey-radio-image{
                max-width: 45%;
                max-height: 200px;
            }
        }
        .option-with-img{
            button.survey-radio-button{
                height: 100%;
                margin: 0px;
            }
        }
        .survey-desc-image-before{
            width: 20%;
            margin-right: 15px;
        }
        .survey-desc-image-after{
            max-height: 200px;
            max-width: 40%;
        }
        .react-datepicker-wrapper{
            width: 100%;
        }
        .survey-required-label{
            color: $survey-required-label-color;
            font-size: $survey-small-font-size;
            font-weight: normal;
            padding: 2px 0 1px;
            text-align: left;
        }
        .survey-questions{
            .content-image{
                max-width: 1200px;
            }
            .label-with-image{
                display: flex;
                align-items: center;
            }
            .survey-remark-label{
                font-size: large;
                font-weight: 600;
            }
            .survey-hint-label{
                width: 50%;
                float: left;
                font-size: $survey-small-font-size;
                font-weight: normal;
                padding: 2px 0 1px;
                color: $survey-hint-label-color;
                text-align: left;
                margin: auto;
            }
            canvas ~ .survey-hint-label{
                width: auto;
            }
        }
    }
    .form-radio-custom{
        + .form-check-label:before{
            content: '';
            background: transparent;
            border: 1px solid #adb5bd;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 10px;
            text-align: center;
            border-radius: 50%;
        }
        &:checked + .form-check-label:before{
            border-color: #3f9ce8;
            background: #3f9ce8;
            box-shadow: inset 0px 0px 0px 4px #fff;
        }
    }
    .was-validated .form-radio-custom{
        &:valid, &.is-valid, &:invalid, &.is-invalid{
            ~ .form-check-label{
                color: inherit;
            }
        }
        &:invalid, &.is-invalid{
            ~ .form-check-label{
                &:before{
                    border-color: $danger-color;
                }
            }
        }
    }
    .was-validated .form-control:valid, .form-control.is-valid{
        border-color: $theme-border;
        background-image: none;
    }
    .custom-select.is-invalid, input.is-invalid, .was-validated .custom-select:invalid, .was-validated input:invalid, .was-validated canvas.empty-field {
        border-color: $danger-color!important;
    }
    .invalid-feedback{
        max-width: 50%;
        width: auto;
        float: right;
        text-align: right;
    }
    .form-check-inline ~ .invalid-feedback{
        width: auto;
        float: unset;
        text-align: left;
    }
}

@media(max-width: 1199px){
    .well-being-survey{
        form{
            .survey-desc-image-after{
                max-width: 80%;
            }
        }
    }
}

@media(max-width: 767px){
    .well-being-survey{
        form{
            input[type="text"]{
                height: calc(25vh*0.2);
            }
            .survey-questions{
                .survey-required-label{
                    margin-bottom: 10px; 
                }
                .label-with-image{
                    align-items: flex-start;
                }
            }
        }
    }
}

#wbs_preview:not(.is-completed) .survey-questions{
    div[class*="question_"].ans-editable{
        cursor: pointer;
        p.survey-lg-label, span.survey-lg-label {
            text-decoration: underline;
            color: #3bafe6;
        }
        &:hover{
            // opacity: 0.6;
            p.survey-lg-label, span.survey-lg-label {
                color: #125faf;
            }
        }
    }
}

.btn{
    font-size: $default-font-size;
    &.btn-outline-info{
        &.disabled, &:disabled{
            opacity: 0.6;
            pointer-events: none;
        }
        &:active, &.active{
            color: #fff;
            background-color: $info;
            border-color: $info;
        }
    }
}

#content-modal .modal-body{
    i{
        font-size: 5.3125rem;
        margin: 20px 0;
        color: $danger;
    }
    p{
        font-size: 18px;
    }
}

#save-modal .modal-body{
    i{
        font-size: 5.3125rem;
        margin: 20px 0;
        color: $success;
    }
    h3{
        margin-bottom: 20px;
    }
}

// wbs dashboard
.wbsDashboard, .card-content{
    position: relative;
}

.wbsDashboard p{
    font-size: 16px;
}

.wbsDashboard #dashboard-spinner, #wbs-spinner, #table-spinner{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .6);
    z-index: 99;
}

.healthRecord-tooltip > .tooltip-inner {
    max-width: 100%;
}

.healthRecord-tooltip p{
    margin-bottom: 5px;
}

.healthRecord-tooltip p.text-indent{
    text-indent: 5px;
}